<template>
  <button
    :type="props.type"
    class="btn"
    :class="{
      'loading-btn': props.isLoading,
      'w-fit': props.style.fit,
    }"
    @click="handleInput"
  >
    <img v-if="props.icon" :src="props.icon" />
    <UiLoadingIcon
      v-if="isLoading"
      size="30px"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    />
    <span v-else>{{ props.label }}</span>
  </button>
</template>

<script lang="ts" setup>
interface IButtonProps {
  type?: "button" | "submit" | "reset";
  label?: string;
  icon?: string;
  isLoading?: Boolean;
  style?: { fit: Boolean };
}

const props = withDefaults(defineProps<IButtonProps>(), {
  type: "button",
  style: () => ({
    fit: false,
  }),
});

const emit = defineEmits<{
  (e: "click", value: boolean): void;
}>();

function handleInput(): void {
  emit("click", true);
}
</script>

<style scoped lang="scss">
.btn {
  @apply flex items-center justify-center relative text-lg rounded-lg text-white bg-gray-700 text-center py-2 px-4 whitespace-nowrap min-w-fit h-11;

  img {
    @apply w-7 h-7 absolute top-1/2 left-3 transform -translate-y-1/2;
    // #ffffff
    filter: invert(100%) sepia(60%) saturate(2%) hue-rotate(127deg) brightness(113%)
      contrast(101%);
  }

  &.loading-btn {
    @apply opacity-80 cursor-wait;

    img {
      // Color black
      filter: invert(0%) sepia(0%) saturate(7469%) hue-rotate(217deg) brightness(108%)
        contrast(108%);
    }
  }
}
</style>
